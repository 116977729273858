import React, { memo } from 'react';
import { graphql } from 'gatsby';
import { MainPage } from '../templates/MainPage';
import SeoText from 'components/SeoText';
import { Helmet } from "react-helmet"

const SemkaRils = () => {
  return (
    <>
      <Helmet>
        <title>Съемка рилс под ключ в Москве цена от студии Elandic</title>
        <meta name="description" content="Видеопродакшн заказать в Москве цена от студии полного цикла Elandic. Заказать услуги видеопродакшена для бизнеса онлайн стоимость на сайте компании."/>
        <meta name="keywords" content="видеопродакшн, москва, студия, заказать, полного цикла, компания .услуги, реклама, цена, для бизнеса, стоимость"/>
      </Helmet>
      <MainPage>
        <SeoText title='Съемка рилс'>
          <p> В мире, где социальные сети становятся ключевыми аренами для продвижения брендов и продуктов, рилс играют жизненно важную роль. Студия видеопродакшн Elandic в Москве предлагает съемку рилс под ключ, включающую комплекс услуг от идеи до финальной реализации, что дает брендам уникальный шанс выделиться.</p>

          <p>В Elandic мы понимаем, как важно презентовать ваш продукт или услугу так, чтобы они запомнились и вызвали интерес у целевой аудитории. Наша студия охватывает весь процесс создания рилс – от разработки концепции и сценария до съемки и монтажа. Мы гарантируем, что каждый этап работы будет выполнен на высшем уровне профессионализма.</p>

          <p>Цены на съемку рилс в студии Elandic зависят от сложности задачи и объема работы. Однако мы стремимся предложить нашим клиентам оптимальное сочетание стоимости и качества услуг. Наша миссия – сделать профессиональный видеопродакшн доступным для бизнеса любого размера.</p>

          <p>Заказать съемку рилс в Москве легко – достаточно обратиться в Elandic, и мы приступим к обсуждению вашего проекта. Мы подходим к каждому заказу индивидуально, учитывая все пожелания и цели клиента.</p>

          <p>Студия Elandic является лидером на рынке видеопродакшена в Москве благодаря индивидуальному подходу к каждому проекту, высокому качеству выполнения работ и конкурентоспособным ценам.</p>

          <p>Съемка рилс под ключ от Elandic – это ваш шанс выделиться в шумном мире социальных медиа и привлечь внимание к вашему бренду. Свяжитесь с нами сегодня, чтобы начать создание уникального контента, который запомнится вашей аудитории.</p>

        </SeoText>
      </MainPage>
    </>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        language: { eq: $language }
        ns: { in: ["mainPage", "common", "layout"] }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default memo(SemkaRils);
